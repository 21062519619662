<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>消息提醒</span>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订阅消息" name="first">
          <el-form :model="form1" ref="form1" label-width="260px">
            <el-form-item label="下单成功提醒(类目: 服装/鞋/箱包 )">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-input v-model="form1.mpwx_appid" autocomplete="off"></el-input>
                </el-col>
                <el-col :span="11">
                  <el-button>查看订阅消息示例</el-button>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item label="下单成功提醒(类目: 服装/鞋/箱包 )">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-input v-model="form1.mpwx_appid" autocomplete="off"></el-input>
                </el-col>
                <el-col :span="11">
                  <el-button>查看订阅消息示例</el-button>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item label="下单成功提醒(类目: 服装/鞋/箱包 )">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-input v-model="form1.mpwx_appid" autocomplete="off"></el-input>
                </el-col>
                <el-col :span="11">
                  <el-button>查看订阅消息示例</el-button>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submit1">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="短信通知" name="second">
          <el-card>
            <div slot="header">
              <span>基本设置</span>
            </div>
            <el-form style="width: 50%" :model="form2" ref="form2" label-width="130px">
              <el-form-item label="短信提醒" prop="name">
                <el-switch></el-switch>
              </el-form-item>

              <el-form-item label="平台" prop="name">
                <el-radio-group v-model="form2.pay_wx_merchant_type">
                  <el-radio label="0">阿里云</el-radio>
                  <el-radio label="1">腾讯云</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="access_key_id" prop="description">
                <el-input
                  v-model="form2.pay_wx_merchant_id"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="access_key_secret">
                <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="模板签名">
                <el-input
                  v-model="form2.pay_wx_apiclient_cert"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="接收短信手机号">
                <el-tag closable>1500494848</el-tag>
                <el-input
                  type="number"
                  style="width: 200px; margin-left: 20px"
                  v-model="form2.pay_wx_apiclient_key"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit2">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>

          <el-card style="margin-top: 20px">
            <div slot="header">
              <span>订单支付提醒设置</span>
            </div>
            <el-form style="width: 50%" :model="form2" ref="form2" label-width="130px">
              <el-form-item prop="name">
                <el-input
                  disabled
                  value="例如：模板内容：您有一条新的订单，订单号：89757，请登录商城后台查看。"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板ID" prop="description">
                <el-input
                  v-model="form2.pay_wx_merchant_id"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板变量">
                <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submit2">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>

          <el-card style="margin-top: 20px">
            <div slot="header">
              <span>订单支付提醒设置</span>
            </div>
            <el-form style="width: 50%" :model="form2" ref="form2" label-width="130px">
              <el-form-item prop="name">
                <el-input
                  disabled
                  value="例如：模板内容：您有一条新的订单，订单号：89757，请登录商城后台查看。"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板ID" prop="description">
                <el-input
                  v-model="form2.pay_wx_merchant_id"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板变量">
                <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submit2">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>

          <el-card style="margin-top: 20px">
            <div slot="header">
              <span>订单支付提醒设置</span>
            </div>
            <el-form style="width: 50%" :model="form2" ref="form2" label-width="130px">
              <el-form-item prop="name">
                <el-input
                  disabled
                  value="例如：模板内容：您有一条新的订单，订单号：89757，请登录商城后台查看。"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板ID" prop="description">
                <el-input
                  v-model="form2.pay_wx_merchant_id"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="模板变量">
                <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submit2">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-tab-pane>

        <el-tab-pane label="邮件通知" name="third">
          <el-form style="width: 50%" :model="form2" ref="form2" label-width="180px">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="规格显示"></el-checkbox>
              <el-checkbox label="货号显示"></el-checkbox>
              <el-checkbox label="下单表单显示"></el-checkbox>
            </el-checkbox-group>
            <el-form-item label="发送平台" prop="description">
              <el-input value="QQ邮箱" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="发件人邮箱">
              <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="授权码">
              <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
              <a
                href="https://service.mail.qq.com/cgi-bin/help?subtype=1&&no=1001256&&id=28"
                >什么时授权码<i class="el-icon-info"></i
              ></a>
            </el-form-item>
            <el-form-item label="发件平台名称">
              <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="收件人邮箱">
              <el-tag closable>test@163.com</el-tag>
              <el-input
                type="email"
                style="width: 200px; margin:0 20px"
                v-model="form2.pay_wx_apiclient_key"
                autocomplete="off"
              ></el-input>
              <el-button>发送测试</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit2">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      tableData1: [],
      dialogFormVisible: false,
      form1: {},
      form2: {},
      product_id: 0,
      products: [],
    };
  },

  created() {
    this.query1();
    this.query2();
  },

  methods: {
    query1() {
      this.$http
        .post("/admin/config/get", ["mpwx_appid", "mpwx_app_secret"])
        .then((res) => {
          this.form1 = res.data;
        })
        .catch(() => {});
    },

    query2() {
      this.$http
        .post("/admin/config/get", [
          "pay_wx_merchant_type",
          "pay_wx_merchant_id",
          "pay_wx_key",
          "pay_wx_apiclient_cert",
          "pay_wx_apiclient_key",
        ])
        .then((res) => {
          this.form2 = res.data;
        })
        .catch(() => {});
    },

    submit1() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/config/save", this.form1).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    submit2() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/config/save", this.form2).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
